import getConfig from "next/config";
import { setCookie } from "nookies";

const { v4: uuidv4 } = require("uuid");

const jwt = require("jsonwebtoken");

// NOTE: exp / iat are in seconds instead of ms
interface JWTPayload {
  created_at: string;
  exp_at: string;
  exp: number;
  iat: number;
  iss: string;
  session_id: string;
}

const EXP_TIME = 60 * 60;

export function getXToken(previousToken?: string, isServer?: boolean): string {
  const secret = process.env.NEXT_PUBLIC_UNAUTH_KEY ?? process.env.UNAUTH_KEY;

  const { serverRuntimeConfig } = getConfig();
  if (previousToken) {
    const { validExpiry } = checkCurrentJwtExp(previousToken);
    if (validExpiry) {
      return previousToken;
    }
  }
  const { uuid } = serverRuntimeConfig;

  const iat = Math.floor(new Date().getTime() / 1000);
  const dateNowISO = new Date().toISOString();
  const exp = Math.floor(new Date().getTime() / 1000) + EXP_TIME;
  const exp_atISO = new Date(exp * 1000).toISOString();
  const jwtPayload: JWTPayload = {
    created_at: dateNowISO,
    // NOTE: exp / iat are in seconds instead of ms
    exp,
    exp_at: exp_atISO,
    iat: iat,
    iss: "10ure-web-jobboard",
    // create a uuid if not on server
    session_id: uuid ?? uuidv4(),
  };
  const xToken = jwt.sign(jwtPayload, secret);
  if (!isServer) {
    setCookie(null, "xToken", xToken, {});
  }

  return xToken;
}

export function checkCurrentJwtExp(xToken?: string | undefined): {
  validExpiry: boolean;
  decoded: JWTPayload | null;
} {
  if (!xToken) {
    return { decoded: null, validExpiry: false };
  }
  try {
    const decoded: JWTPayload = jwt.decode(xToken);
    const now = new Date();
    const exp_ms = decoded.exp * 1000;
    const timeDiff = exp_ms >= now.getTime();
    return {
      decoded,
      validExpiry: timeDiff,
    };
  } catch (e) {
    throw new Error("Error decoding jwt");
  }
}
