export enum MethodType {
  GET = "GET",
  PATCH = "PATCH",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
}

export enum ContentType {
  JSON = "application/json",
  MP_FORM_DATA = "multipart/form-data",
}

export enum ResponseStatus {
  SUCCESS,
  ERROR,
}

export interface SuccessMessage {
  msg: string;
}

export interface APIFail {
  status: ResponseStatus.ERROR;
  error: string; // any?
}

export interface APISuccess<T> {
  status: ResponseStatus.SUCCESS;
  body: T;
}

// T being the type of your expected successful response data
export type APIResponseUnion<T> = APIFail | APISuccess<T>;
export type APIResponse<T> = Promise<APIResponseUnion<T>>;
