/**
 * This generates an xToken on our next.js server and returns to the client
 * since need access to env variable
 * @returns xToken or error
 */
export async function fetchXToken(): Promise<{
  xToken: string | null;
  error: string | null;
}> {
  try {
    const response = await fetch("/api/generate-x-token");
    if (!response.ok) {
      throw new Error("Failed to fetch xToken");
    }
    const data = await response.json();
    const newXToken = data.xToken;
    return {
      error: null,
      xToken: newXToken,
    };
  } catch (error) {
    console.error("Error fetching new xToken:", error);
    return {
      error: "Error with token generation. Please try again.",
      xToken: null,
    };
    // Handle error appropriately
  }
}
