export enum ServiceEndpoints {
  AUTH = "https://dev-auth-api.10ure.com/api",
  MARINER = "https://dev-mariner-api.10ure.com/api",
  JOBS = "https://dev-marinerjob-api.10ure.com/api",
  SEATIME = "https://dev-marinerseatime-api.10ure.com/api",
  APPLICANTS = "https://applicants-api.10ure.com/api",
  NOTIFY = "https://dev-pushnotify-api.10ure.com/api",
  ORG = "https://organization-api.10ure.com/api",
  DEEPLINK = "https://deeplink.10ure.com",
  NEWS = "https://maritimenews-api.10ure.com/api",
  MARITIMECREDENTIALING = "https://maritimecredentialing-api.10ure.com/api",
}
