import { AxiosRequestConfig } from "axios";
import { parseCookies } from "nookies";
import { GetServerSidePropsContext } from "next";
import { checkCurrentJwtExp, getXToken } from "./unAuthSetup";
import { ContentType } from "./types";
import { fetchXToken } from "./fetchXToken";

export async function setupHeaders(options: {
  ctx?: GetServerSidePropsContext;
  contentType?: ContentType | null;
  unAuthOnly?: boolean;
  customHeaders?: HeadersInit;
}): Promise<AxiosRequestConfig["headers"]> {
  const { ctx, contentType, unAuthOnly, customHeaders } = options;
  const { userToken: token, xToken } = parseCookies(ctx);
  // setup headers / add token if needed
  let baseHeaders = customHeaders ? { ...customHeaders } : {};
  baseHeaders = {
    ...baseHeaders,
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Headers": "true",
    "Access-Control-Allow-Methods": "PUT, POST, PATCH, DELETE, GET",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Expose-Headers": "true",
  };
  if (!contentType) {
    baseHeaders = {
      ...baseHeaders,
      "Content-Type": "application/json",
    };
  }

  if (!token || unAuthOnly) {
    const { validExpiry } = checkCurrentJwtExp(xToken);

    if (!xToken || !validExpiry) {
      const { xToken: newXToken, error } = await fetchXToken();
      if (!newXToken || error) {
        throw Error("Error fetching new xToken");
      }
      return {
        ...baseHeaders,
        "X-Request-Token": newXToken,
      };
    }
    return {
      ...baseHeaders,
      "X-Request-Token": xToken,
    };
  }

  return {
    ...baseHeaders,
    Authorization: token ?? undefined,
  };
}

/**
 * NOTE - the caller should handle calling in a spot where
 * you are authed or not correctly. Ex: Should not allow calling
 * authed route from an un-authed location.
 * @deprecated -- phasing out -- the xToken generation is not correct here unless it's done server side
 * only used in useSWR
 * @param contentType
 * @returns
 */
export function setupHeadersOld(options: {
  ctx?: GetServerSidePropsContext;
  contentType?: ContentType | null;
  unAuthOnly?: boolean;
  customHeaders?: HeadersInit;
}): AxiosRequestConfig["headers"] {
  const { ctx, contentType, unAuthOnly, customHeaders } = options;
  const { userToken: token, xToken } = parseCookies(ctx);
  // setup headers / add token if needed
  let baseHeaders = customHeaders ? { ...customHeaders } : {};
  baseHeaders = {
    ...baseHeaders,
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Headers": "true",
    "Access-Control-Allow-Methods": "PUT, POST, PATCH, DELETE, GET",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Expose-Headers": "true",
  };
  if (!contentType) {
    baseHeaders = {
      ...baseHeaders,
      "Content-Type": "application/json",
    };
  }

  if (!token || unAuthOnly) {
    const { validExpiry } = checkCurrentJwtExp(xToken);

    if (!xToken || !validExpiry) {
      const newXToken = getXToken();
      return {
        ...baseHeaders,
        "X-Request-Token": newXToken,
      };
    }
    return {
      ...baseHeaders,
      "X-Request-Token": xToken,
    };
  }

  return {
    ...baseHeaders,
    Authorization: token ?? undefined,
  };
}

/**
 * Pass in XToken - always an unauth call - no access to cookies
 * @param contentType
 * @param xToken
 */
export function setupSSRHeaders(
  xToken?: string,
  contentType?: ContentType | null
) {
  // setup headers / add token if needed
  let baseHeaders: any = {
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Headers": "true",
    "Access-Control-Allow-Methods": "PUT, POST, PATCH, DELETE, GET",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Expose-Headers": "true",
    "Content-Type": "application/json",
  };
  if (contentType) {
    baseHeaders = {
      ...baseHeaders,
      "Content-Type": contentType,
    };
  }

  if (!xToken) {
    return baseHeaders;
  }
  return {
    ...baseHeaders,
    "X-Request-Token": xToken,
  };
}

/**
 * NOTE - the caller should handle calling in a spot where
 * you are authed or not correctly. Ex: Should not allow calling
 * authed route from an un-authed location.
 * @param contentType
 * @returns
 */
export function setupCallHeaders(
  contentType: ContentType | null = null
): AxiosRequestConfig["headers"] {
  const { userToken: token, xToken } = parseCookies(null);

  // setup headers / add token if needed
  let baseHeaders = {};
  if (contentType) {
    baseHeaders = {
      ...baseHeaders,
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };
  }

  if (!token) {
    return {
      ...baseHeaders,
      "X-Request-Token": xToken,
    };
  }
  return {
    ...baseHeaders,
    Authorization: token ?? undefined,
  };
}
