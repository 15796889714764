import { camelCase, snakeCase } from "lodash";

export const encodeToCamelCase = (obj: {
  [x: string]: any;
}): { [x: string]: any } => {
  // Object
  if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce((result, key) => {
      let val: any = obj[key];
      if (val instanceof Object) {
        val = encodeToCamelCase(val);
      }
      return {
        ...result,
        [camelCase(key)]: val,
      };
    }, {});
    // Array
  } else if (obj.constructor === Array) {
    // @ts-ignore
    const newArray = obj.map((item: any) => {
      return encodeToCamelCase(item);
    });
    return newArray;
  }
  return obj;
};

export const encodeToSnakeCase = (obj: {
  [x: string]: any;
}): { [x: string]: any } => {
  if (obj !== null && obj.constructor === Object) {
    return Object.keys(obj).reduce((result, key) => {
      let val: any = obj[key];
      if (val instanceof Object) {
        val = encodeToSnakeCase(val);
      }
      return {
        ...result,
        [snakeCase(key)]: val,
      };
    }, {});
    // Array
  } else if (obj.constructor === Array) {
    // @ts-ignore
    const newArray = obj.map((item: any) => {
      return encodeToSnakeCase(item);
    });
    return newArray;
  }
  return obj;
};
